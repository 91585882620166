"use client";

import * as React from "react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { type ThemeProviderProps } from "next-themes/dist/types";
export function ThemeProvider({
  children,
  defaultTheme,
  ...props
}: ThemeProviderProps) {
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);

  // Return a skeleton that matches the server-side HTML structure
  if (!mounted) {
    return <div className={defaultTheme === "dark" ? "dark" : "light"}>
        {children}
      </div>;
  }
  return <NextThemesProvider defaultTheme={defaultTheme} {...props} data-sentry-element="NextThemesProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="theme-provider.tsx">
      {children}
    </NextThemesProvider>;
}