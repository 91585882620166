"use client";

import { Button } from "@/components/ui/button";
import { SignedIn, SignedOut, SignInButton } from "@clerk/nextjs";
import { Phone, PhoneCall, PhoneForwarded, PhoneIncoming, PhoneOff } from "lucide-react";
import Link from "next/link";
import { useEffect, useState } from "react";
export function HeroSection() {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(true);
  }, []);
  return <section className="relative overflow-hidden bg-gradient-to-b from-blue-50 to-white dark:from-gray-900 dark:to-gray-800" data-sentry-component="HeroSection" data-sentry-source-file="hero.tsx">
      {/* Animated phone icons background */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-1/4 left-1/4 animate-float-slow">
          <Phone className="w-12 h-12 text-blue-200 dark:text-blue-800" data-sentry-element="Phone" data-sentry-source-file="hero.tsx" data-sentry-element="Phone" />
        </div>
        <div className="absolute top-3/4 left-1/3 animate-float">
          <PhoneCall className="w-16 h-16 text-green-200 dark:text-green-800" data-sentry-element="PhoneCall" data-sentry-source-file="hero.tsx" data-sentry-element="PhoneCall" />
        </div>
        <div className="absolute top-1/2 right-1/4 animate-float-delayed">
          <PhoneForwarded className="w-14 h-14 text-purple-200 dark:text-purple-800" data-sentry-element="PhoneForwarded" data-sentry-source-file="hero.tsx" data-sentry-element="PhoneForwarded" />
        </div>
        <div className="absolute bottom-1/4 right-1/3 animate-float-slow">
          <PhoneIncoming className="w-10 h-10 text-yellow-200 dark:text-yellow-800" data-sentry-element="PhoneIncoming" data-sentry-source-file="hero.tsx" data-sentry-element="PhoneIncoming" />
        </div>
        <div className="absolute top-1/3 right-1/2 animate-float">
          <PhoneOff className="w-12 h-12 text-red-200 dark:text-red-800" data-sentry-element="PhoneOff" data-sentry-source-file="hero.tsx" data-sentry-element="PhoneOff" />
        </div>
      </div>

      {/* Content */}
      <div className="relative">
        <div className="container px-4 md:px-6 mx-auto">
          <div className="flex flex-col items-center justify-center gap-4 py-16 md:py-20">
            {/* Main content */}
            <div className={`space-y-6 transition-all duration-1000 ${isVisible ? "translate-y-0 opacity-100" : "translate-y-10 opacity-0"}`}>
              <div className="space-y-4 text-center">
                <h1 className="text-4xl font-bold tracking-tighter sm:text-5xl md:text-6xl lg:text-7xl">
                  Pick up the phone{" "}
                  <span className="relative inline-block">
                    <span className="relative z-10 bg-gradient-to-r from-blue-600 to-violet-600 bg-clip-text text-transparent dark:from-blue-400 dark:to-violet-400">
                      feeling confident
                    </span>
                    <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-blue-600/20 to-violet-600/20 blur-sm dark:from-blue-400/20 dark:to-violet-400/20" />
                  </span>
                  <br />
                  It&apos;s worth your time.
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-600 md:text-xl dark:text-gray-300">
                  Distracted by irrelevant calls? Let TidyCalls screen them, so
                  you can focus on real opportunities and never miss what
                  matters.
                </p>
              </div>

              {/* CTA Buttons */}
              <div className="flex flex-wrap justify-center gap-3">
                <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="hero.tsx" data-sentry-element="SignedIn">
                  <Link href="/dashboard" data-sentry-element="Link" data-sentry-source-file="hero.tsx" data-sentry-element="Link">
                    <Button size="lg" className="relative overflow-hidden bg-black hover:bg-gray-800 transition-colors" data-sentry-element="Button" data-sentry-source-file="hero.tsx" data-sentry-element="Button">
                      <span className="relative z-10">Go to Dashboard</span>
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-violet-600 opacity-0 hover:opacity-20 transition-opacity" />
                    </Button>
                  </Link>
                </SignedIn>
                <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="hero.tsx" data-sentry-element="SignedOut">
                  <SignInButton mode="modal" data-sentry-element="SignInButton" data-sentry-source-file="hero.tsx" data-sentry-element="SignInButton">
                    <Button size="lg" className="relative overflow-hidden bg-black hover:bg-gray-800 transition-colors" data-sentry-element="Button" data-sentry-source-file="hero.tsx" data-sentry-element="Button">
                      <span className="relative z-10">Get Started</span>
                      <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-violet-600 opacity-0 hover:opacity-20 transition-opacity" />
                    </Button>
                  </SignInButton>
                </SignedOut>
                <Link href="/pricing" data-sentry-element="Link" data-sentry-source-file="hero.tsx" data-sentry-element="Link">
                  <Button variant="outline" size="lg" className="border-2 hover:bg-gray-100/50 dark:hover:bg-gray-800/50 transition-colors" data-sentry-element="Button" data-sentry-source-file="hero.tsx" data-sentry-element="Button">
                    View Pricing
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
}