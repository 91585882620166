"use client";

import { UserPlus, FileText, BarChart3 } from "lucide-react";
import { DemoCard } from "./democard";
export function ProductDemoSection() {
  return <section data-sentry-component="ProductDemoSection" data-sentry-source-file="screens.tsx">
      {/* Remove any top or bottom margin/padding here */}
      <h2 className="text-3xl font-bold text-center mb-12">How it Works</h2>
      <div className="grid gap-8 md:grid-cols-3">
        <DemoCard icon={<UserPlus className="w-10 h-10 text-blue-600" />} title="Onboard your AI receptionist" description="Sign in, subscribe, and give verbal instructions for your dedicated, 24/7 AI receptionist." steps={["Create your account", "Choose a subscription plan", "Call your personal AI receptionist - just scan the QR"]} imageSrc="/moshizen-operator-female-2.jpg?height=200&width=300" imageAlt="Moshi Zen sign-up process screenshot" data-sentry-element="DemoCard" data-sentry-source-file="screens.tsx" data-sentry-element="DemoCard" />
        <DemoCard icon={<FileText className="w-10 h-10 text-blue-600" />} title="Full Call Transcripts" description="Never miss the backstory with full transcripts of all your calls, including ones that were not passed through to you." steps={["Share your new phone number (or use as voicemail!)", "Enjoy your pre-screened calls", "Review transcripts in your dashboard at your leisure"]} imageSrc="/moshizen-transcripts.png?height=200&width=300" imageAlt="Transcripts at your disposal" data-sentry-element="DemoCard" data-sentry-source-file="screens.tsx" data-sentry-element="DemoCard" />
        <DemoCard icon={<BarChart3 className="w-10 h-10 text-blue-600" />} title="Performance Analytics" description="Analyze your receptionist's performance and change instructions on the fly." steps={["View detailed performance metrics", "Identify areas for improvement", "Adjust instructions for better results"]} imageSrc="/moshizen-dashboard.png?height=200&width=300" imageAlt="TidyCalls sign-up process screenshot" data-sentry-element="DemoCard" data-sentry-source-file="screens.tsx" data-sentry-element="DemoCard" />
      </div>
    </section>;
}