"use client";

import { Button } from "@/components/ui/button";
import { SignedIn, SignedOut, SignInButton } from "@clerk/nextjs";
export function AuthButton() {
  return <Button data-sentry-element="Button" data-sentry-component="AuthButton" data-sentry-source-file="auth-button.tsx">
      <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="auth-button.tsx" data-sentry-element="SignedIn">
        <a href="/onboarding">Get Started</a>
      </SignedIn>
      <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="auth-button.tsx" data-sentry-element="SignedOut">
        <SignInButton data-sentry-element="SignInButton" data-sentry-source-file="auth-button.tsx" data-sentry-element="SignInButton">
          <a href="/onboarding">Get Started</a>
        </SignInButton>
      </SignedOut>
    </Button>;
}